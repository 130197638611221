import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import flash from '/lib/flash'

import parse    from 'date-fns/parseISO'
import format   from 'date-fns/format'
import isFuture from 'date-fns/isFuture'
import isToday  from 'date-fns/isToday'
import groupBy  from 'underscore-es/groupBy'
import pluck    from 'underscore-es/pluck'
import uniq     from 'underscore-es/uniq'

import Models         from '/models/index'
import ReactSelect    from '/components/react-select'
import Spinner        from '/components/spinner'

import {
  withTicket
  withTicketMove
} from './queries'

import { Query }  from 'react-apollo'
import EVENTS_GQL from './queries/events.gql'

export default \
withTicket \
withTicketMove \
class Move extends React.Component
  @propTypes:
    id:   PropTypes.string

  constructor: ->
    super arguments...
    @state =
      date: null

  futureDates: ->
    @props.ticket.listing.calendar_dates
    .map parse
    .filter (d)-> isFuture(d) or isToday(d)
    .map (d)-> format d, 'yyyy-LL-dd'

  cis_by_rate: ->
    groupBy @props.ticket.raw_cost_items, 'rate_id'

  eventOptions: (events)=>
    cis_by_rate = @cis_by_rate()

    (for event in events when event.id isnt @props.ticket.event.id
      d = parse event.start_time.slice 0, 19

      value: event.id
      label:
        <div>
          <div className="date-container">
            <div className="date">{format d, 'PPPPp'}</div>
            {<span className="label label-xs label-warning">Toggled Off</span> if event.all_rates_disabled}
          </div>
          {(for cap in event.capacities when cis_by_rate[cap.rate_id]?.length
            <div className="capacity" key={cap.rate_id}>
              {cap.rate_name}: {if cap.count_available? then cap.count_available else 'unlimited'} available {' '}
              {if cap.count_available? && (cis_by_rate[cap.rate_id]?.length or 0) > cap.count_available
                <span className="label label-xs label-danger">EXCEEDS CAPACITY BY {(cis_by_rate[cap.rate_id]?.length or 0) - cap.count_available}</span>
              }
            </div>
          )}
        </div>
    )

  moveTicket: ({value: event_id})=>
    {errors} = await @props.move_ticket @props.ticket.id, event_id
    return flash.error errors if errors?
    flash.success "Ticket moved"
    @props.done?()

  render: ->
    return null if @props.ticketLoading
    d = parse @props.ticket.event.start_time.slice 0, 19
    <div className="TicketMove">
      Move ticket from:
      <br/>
      <br/>
      <div className="date">{format d, 'PPPPp'}</div>
      {(<div className="capacity" key={rate_id}>{cis[0].name}: {cis.length}</div> for rate_id, cis of @cis_by_rate())}
      <br/>
      to:
      {@dateSelector()}
      <br/>
      {@eventSelector()}
    </div>

  dateSelector: ->
    <ReactSelect
      className="date-selector"
      options={@futureDates().map (d)-> value: d, label: format parse(d), 'PPPP'}
      multi={false}
      onChange={({value: date})=> @setState {date}}
      value={@state.date}
      placeholder="Select date"
    />

  eventSelector: ->
    { id } = @props # ticket id
    { date } = @state
    rate_ids = uniq pluck @props.ticket.raw_cost_items, 'rate_id'
    return null unless date
    <Query query={EVENTS_GQL} variables={{id, date, rate_ids}}>
      {({ loading, error, data })=>
        return <Spinner/> if loading
        if error?
          flash.error error
          return null

        events = Models.Event.parse data.ticket?.listing?.raw_events or []

        <ReactSelect
          className="event-selector"
          options={@eventOptions events}
          multi={false}
          onChange={@moveTicket}
          placeholder="Move ticket to..."
        />
      }
    </Query>

import React from 'react'

import flash from '/lib/flash'

# components
import Spinner        from '/components/spinner'
import Empty          from '/components/empty'
import CostItemCard   from '/components/cost-item-card'
import Note           from '/components/note'
import HeroStats      from '/components/hero-stats'
import Price          from '/components/price'
import StateActions   from '/components/state-label/actions'
import EventCard      from '/components/event-card'
import UserCard       from '/components/user-card'
import Datetime       from '/components/datetime'
import Answer         from '@uniiverse/library/components/answer'

import format         from 'date-fns/format'
import subSeconds     from 'date-fns/subSeconds'
import isFuture       from 'date-fns/isFuture'
import humanize       from 'underscore.string/humanize'

import Modal          from 'react-bootstrap/lib/Modal'
import ModalState     from '/components/modal-state'
import TicketMove     from '/components/ticket/move'

import CalendarIcon  from 'react-icons/lib/fa/calendar'

import {
  withTicket
  withTicketsRPC
  withTicketsResend
  withCostItems
  withActiveNotes
} from './queries'


export default \
withTicket \
withCostItems \
withActiveNotes \
withTicketsRPC \
withTicketsResend \
class Summary extends React.Component
  rpc: (id, method)->
    return @resend_ticket id if method is 'resend_ticket'
    {ticket, errors} = await @props.tickets_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket updated successfully"

  resend_ticket: (id)=>
    {ticket, errors} = await @props.tickets_resend id
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket re-sent successfully"

  render: ->
    return <Spinner/> if @props.ticketLoading
    return <Empty name="Ticket" /> unless @props.ticket?.id

    <div className="Summary">
      {@infoOverview()}
      {@notes()}
      {@details()}
      <div className="more">
        {@attributes()}
        {@hostFields()}
      </div>
    </div>

  infoOverview: ->
    <div className="sales-overview">
      <h2>Ticket Overview</h2>
      <HeroStats data={[
          name: "State"
          value: <StateActions
            model={@props.ticket}
            onClick={@rpc.bind @, @props.ticket.id}
            position='left'
          />
        ,
          name: "Amount (#{@props.ticket.base_currency})"
          value: <Price amount={@props.ticket.price} currency={@props.ticket.base_currency} showCurrency={false}/>
          tooltip: @props.ticket.base_currency
        ,
          name: "Purchased"
          value: <Datetime date={@props.ticket.created_at} inline={true}/>
        ,
          name: "Editable"
          value: if isFuture @editable_until() then "✅" else "❌"
          tooltip: @tooltipify 'editable', @props.ticket.event.start_time, @props.ticket.listing.noneditable_offset
        ,
          name: "Transferable"
          value: if isFuture @transferable_until() then "✅" else "❌"
          tooltip: @tooltipify 'transferable', @props.ticket.event.start_time, @props.ticket.listing.nontransferrable_offset
        ]}/>
    </div>

  editable_until: =>
    @offsetTime @props.ticket.event.start_time, @props.ticket.listing.noneditable_offset

  transferable_until: =>
    @offsetTime @props.ticket.event.start_time, @props.ticket.listing.nontransferrable_offset

  offsetTime: (start_time, offset)->
    subSeconds new Date(start_time), offset

  tooltipify: (word, start_time, offset)=>
    switch offset
      when 0 then "Not #{word} after event #{if isFuture(new Date(start_time)) then "starts" else "started" } on #{format new Date(start_time), 'MMM d, yyyy h:mm a'}"
      when undefined then "Host set to: never #{word}"
      else "#{word} until: #{format @offsetTime(start_time, offset), 'MMM d, yyyy h:mm a'}"

  notes: ->
    return null unless @props.notes?.length
    <div className="notes">
      {<Note key={note._key} note={note}/> for note in @props.notes}
    </div>

  details: ->
    <div className="details">

      <div className="detail">
        <h3>Event</h3>
        <div className="panel panel-default">
          <div className="panel-body">
            <EventCard event={@props.ticket.event} />
          </div>
          <div className="panel-footer">
            {@moveTicket()}
          </div>
        </div>
      </div>

      <div className="detail">
        <h3>Buyer</h3>
        <div className="panel panel-default">
          <div className="panel-body">
            <UserCard user={@props.ticket.user} />
          </div>
        </div>

        {if @props.ticket.host_purchased_by
          <>
            <h3>Host Purchased by</h3>
            <div className="panel panel-default">
              <div className="panel-body">
                <UserCard user={@props.ticket.user} />
              </div>
            </div>
          </>
        }
      </div>

      <div className="detail tags">
        <h3>Properties</h3>
        <div className="panel panel-default">
          <div className="panel-body">
            {@tags()}
          </div>
        </div>
      </div>

    </div>

  attributes: ->
    return null unless t = @props.ticket
    row = (attr, title)->
      return null unless t[attr]?
      <div className="form-group">
        <label className="control-label">{title}</label>
        <input value={t[attr] or ''} type='text' className="form-control" disabled="disabled"/>
      </div>

    <form className="attributes">
      <h2>Order Information</h2>
      {row 'provider',                'Payment Provider'}
      {row 'provider_source_type',    'Source Type'}
      {row 'card_brand',              'Card Brand'}
      {row 'last_four_digits',        'Last 4 digits'}
      {row 'origin_client',           'Origin Client'}
      {row 'postal_code',             'Postal Code'}
      {row 'booking_protect_fee',     'Order Protection Fee'}
      {row 'booking_protect_state',   'BP Sales API response'}
      {row 'discount_code',           'Discount Code Applied'}
    </form>

  hostFields: ->
    return null unless @props.ticket?.host_fields?.length
    <div className="host_fields">
      <h2>Order-level Checkout Questions</h2>
      {(for hf in @props.ticket.host_fields
        <Answer
          key={hf.id}
          answer={hf}
          onChange={->}
        />
      )}
    </div>

  tags: ->
    attrs = "buyer_cancelable guest_checkout order_protection host_purchased needs_approval using_payment_plan".split(' ')
    (for attr in attrs
      <span
        key={attr}
        className="label label-info"
        disabled={not @props.ticket[attr]}
      >
        {humanize attr}
      </span>
    )


  costItemCards: ->
    return <Spinner/> if @props.cost_itemsLoading
    <div>
      <h2>Cost Items</h2>
      <div className="cost-items">
        {for ci in @props.cost_items
          <CostItemCard
            key={ci.id}
            listing={@props.ticket.listing}
            cost_item={ci}
          />
        }
      </div>
    </div>


  moveTicket: ->
    <ModalState
      render={({open, toggle})=>
        [
          <button key={'icon'} className='btn btn-default' onClick={toggle}>
            <CalendarIcon/>{' '}Move ticket
          </button>

          <Modal key={'modal'} show={open} onHide={toggle}>
            <Modal.Header closeButton>
              <Modal.Title>
                Move Ticket
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <TicketMove id={@props.ticket.id} done={toggle}/>
            </Modal.Body>
          </Modal>
        ]
    }/>
